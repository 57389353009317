import Script from 'next/script';
import React from 'react';
import { useEffect } from 'react';
import { useSearchParam } from 'react-use';

import { pushPageViewToDataLayer } from '../../../helpers/googleTagManager';

import { useGlobalSettingsStore } from '@/contexts/globalSettings';

interface Props {
  domainGroupId?: string;
  language?: string;
  solution?: 'com' | 'eu';
}

const DATA_CBID = 'data-cbid';

const CookieBot: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  domainGroupId = undefined,
  language = undefined,
  solution = 'com',
}) => {
  const setCookieConsents = useGlobalSettingsStore(
    (store) => store.setCookieConsents,
  );

  const disableCookieBot = useSearchParam('disableCookieBot');

  useEffect(() => {
    const onAcceptCookies = () => {
      pushPageViewToDataLayer();

      return window.Cookiebot
        ? setCookieConsents(window.Cookiebot.consent)
        : null;
    };

    window.addEventListener('CookiebotOnConsentReady', onAcceptCookies);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', onAcceptCookies);
    };
  }, [setCookieConsents]);

  useEffect(() => {
    const onCookiebotOnConsentReady = () =>
      window.Cookiebot ? setCookieConsents(window.Cookiebot.consent) : null;

    window.addEventListener(
      'CookiebotOnConsentReady',
      onCookiebotOnConsentReady,
    );

    return () => {
      window.removeEventListener(
        'CookiebotOnConsentReady',
        onCookiebotOnConsentReady,
      );
    };
  }, [setCookieConsents]);

  if (disableCookieBot === 'true') {
    return null;
  }

  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return null;
  }
  if (!domainGroupId || !document) {
    return null;
  }
  if (domainGroupId.length !== 36) {
    console.warn(
      `The cookie bot domain group id is ${domainGroupId.length} characters, instead it should be 36 characters!`,
    ); // eslint-disable-line no-console
  }

  if (
    document.querySelectorAll(`[${DATA_CBID}="${domainGroupId}"]`).length > 0
  ) {
    return null;
  }

  return (
    <Script
      id="CookieBot"
      src={`https://consent.cookiebot.${solution}/uc.js`}
      data-cbid={domainGroupId}
      data-blockingmode="auto"
      data-culture={language ? language : undefined}
    />
  );
};

export default CookieBot;
